import { PageNotFound } from '@play-now/video/pages/Error/PageNotFound';
import { UnknownError } from '@play-now/video/pages/Error/UnknownError';
import { isRouteErrorResponse, Outlet, useRouteError } from '@remix-run/react';

import { PlayLayout } from './PlayLayout';

const Index = () => <Outlet />;

export default Index;

export const ErrorBoundary = () => {
  const error = useRouteError();
  const status = isRouteErrorResponse(error) ? error.status : 500;
  const isNotFound = status === 404;

  return <PlayLayout pageId={isNotFound ? 'pageNotFound' : 'unknownError'}>{isNotFound ? <PageNotFound /> : <UnknownError />}</PlayLayout>;
};
